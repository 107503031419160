import React, { useEffect, useState } from 'react';
import About from './components/About';
import Sectionone from './components/Sectionone';
import Footer from './components/Footer';
import Product from './components/Product';
import Partner from './components/Partner';
import Angka from './components/Angka';



import Hero from './components/Hero';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const [showChat, setShowChat] = useState(false);
  const [message, setMessage] = useState('');

  const handleClick = () => {
    setShowChat(!showChat);
  };

  const handleClose = () => {
    setShowChat(false);
  };

  const handleSend = () => {
    const whatsappURL = `https://wa.me/6281398635856?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
    setShowChat(false); // Close the chat bubble after sending the message
  };

  return (
    <div>
      <Hero />
      <Sectionone />
      <Angka />
      <About />
      
      <Product />
      <Partner />
      <iframe
        id="footer"
        title="Google Maps"
        width="100%"
        height="400"
        frameBorder="0"
        style={{ border: 0 }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d269.9637010509567!2d104.71640722089629!3d-2.9282948116793035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e3b75dd96407be1%3A0x36ac85f48e6aa751!2sKim%20Auto%20-%20Jual%20Beli%20Mobil%20Bekas!5e0!3m2!1sid!2sid!4v1728046862874!5m2!1sid!2sid"
        allowFullScreen
      ></iframe>
      <Footer />
      <div className="relative" >
        <button
          onClick={handleClick}
          className="fixed bottom-4 right-4 z-50 flex items-center group"
        >
          <div className="relative flex items-center justify-center w-14 h-14 bg-green-500 rounded-full">
      <img
        src="https://img.icons8.com/color/48/000000/whatsapp--v1.png"
        alt="WhatsApp"
        className="w-10 h-10"
      />
    </div>
          <span className="whatsapp-tooltip absolute left-[-130px] bottom-30 bg-gray-100 text-gray-900 px-3 py-1 rounded-md group-hover:block blink">
            Hubungi Kami
          </span>
        </button>
      </div>
      {showChat && (
        <div data-aos="fade-up"  className="fixed bottom-20 right-4 z-50 bg-gray-200 p-4 rounded-lg shadow-lg w-80">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-center   mx-auto">Chat dengan kami</p>
            <button
              onClick={handleClose}
              className="border border-gray-50 bg-gray-500 rounded-full w-6 h-6 flex items-center justify-center font-bold text-white"
            >
              X
            </button>
          </div>
          <div className="chat chat-start mb-2 " > 
            <div className="speech-bubble-left rounded-tl-none bg-white font-sans p-2 rounded-xl w-5/6 mb-32">
              Halo! Ada yang bisa di bantu ?
            </div>
          </div>
          <hr class=" border-gray-600 sm:mx-auto mb-1" />
          <div className="flex items-center border-t p-2 bg-white rounded-b-lg">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full p-2 border-none font-sans  rounded-l-lg focus:ring-0"
              rows="1"
              placeholder="Tulis pesan Anda di sini..."
            ></textarea>
            <button
              onClick={handleSend}
              className="bg-green-500 text-white p-2 rounded-full ml-2"
            >
              
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
</svg>

            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
