import React, { useEffect } from 'react'
import { FaInstagram } from "react-icons/fa";
import { AiFillTikTok, AiFillFacebook } from "react-icons/ai";
import { MdOutlineMail } from "react-icons/md";
import AOS from 'aos';
import 'aos/dist/aos.css';



function Footer() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  
  return (
    <div>
      <footer className="bg-stone-800" >
        <div className="mx-auto max-w-6xl px-5  py-10 "data-aos="fade-up">
          <p className='sm:text-3xl text-2xl text-white'>ALAMAT</p>
          <p className="mx-auto font-sans mt-6 text-sm mb-3 text-left leading-relaxed text-white ">
            Jln. Perindustrian 1 Sukarami,Kec.Sukarami
          </p>
          <p className="mx-auto font-sans mb-3 text-sm text-left leading-relaxed text-white ">
           Kota Palembang, Sumatera Selatan 30153, Indonesia 
          </p>
          <p className="mx-auto font-sans mb-3 text-sm text-left leading-relaxed text-white ">
            +6281398635856
          </p>
          <p className="mx-auto  font-sans mb-3 text-sm text-left leading-relaxed text-white ">
            kim2105jr@gmail.com
          </p>
          <p className="mt-12 text-xl sm:text-2xl text-center leading-6 text-white transition hover:text-red-600" > FOLLOW US </p>
          


          <ul className="mt-6 flex justify-center gap-2  ">

            <li className='transition'><a href="https://www.instagram.com/mobil.secondpalembang" aria-label="Twitter (opens in a new tab)" target="_blank" rel="noopener noreferrer"><FaInstagram className='text-white w-14 h-14  hover:text-pink-600  hover:border-blue-700 border-[1px] border-gray-100  p-3' /></a></li>
            <li className='transition'><a href="https://www.facebook.com/share/4wwyX7AhSFY5imwq/?mibextid=qi2Omg" aria-label="facebook (opens in a new tab)" target="_blank" rel="noopener noreferrer"><AiFillFacebook className='text-white w-14 h-14 hover:text-blue-700  hover:border-blue-700 border-[1px] border-gray-100  p-3' /></a></li>
            <li className='transition'><a href="https://www.tiktok.com/@mobil.secondpalembang" aria-label="Twitter (opens in a new tab)" target="_blank" rel="noopener noreferrer"><AiFillTikTok className='text-white w-14 h-14 hover:text-black  hover:border-blue-700 border-[1px] border-gray-100  p-3' /></a></li>

          </ul>
          <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <span class="block text-xs sm:text-sm text-white font-sans sm:text-center">© 2024 Kim Auto Palembang. All rights reserved.</span>
    
        </div>
      </footer>
    </div>
  )
}

export default Footer
