import React, { useEffect } from 'react'
import ImageGallery from 'react-image-gallery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import banner1Png from '../../src/assets/cus.1.jpg';
import banner2Png from '../../src/assets/cus.2.jpg';
import banner3Png from '../../src/assets/cus.3.jpg';
import banner4Png from '../../src/assets/cus.4.jpg';
import banner5Png from '../../src/assets/cus.5.jpg';
import banner6Png from '../../src/assets/cus.6.jpg';
import banner7Png from '../../src/assets/cus.7.jpg';
import banner8Png from '../../src/assets/cus.8.jpg';
function Partner() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    const images = [
        {
          original: banner3Png,
          thumbnail: banner3Png,
        },
        {
          original: banner1Png,
          thumbnail: banner1Png,
        },
        {
          original: banner2Png,
          thumbnail: banner2Png,
        },
        {
          original: banner4Png,
          thumbnail: banner4Png,
        },
        {
          original: banner5Png,
          thumbnail: banner5Png,
        },
        {
          original: banner6Png,
          thumbnail: banner6Png,
        },
        {
          original: banner7Png,
          thumbnail: banner7Png,
        },
        {
          original: banner8Png,
          thumbnail: banner8Png,
        },
      ];
    return (
        <div>
           

            <section id='partner'>
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
                    <header className="text-center">
                        <h2 className="text-4xl  text-gray-900 sm:text-5xl">Testimoni  <span className="text-red-600">Customer</span></h2>

                        <p className="mx-auto mt-4 max-w-3xl font-sans text-gray-700">
                            
"Kami menjamin kualitas terbaik pada setiap mobil yang kami jual."
                        </p>
                    </header>

                    <div id="gallery" className="relative w-11/12 sm:w-3/4 mx-auto my-10 z-10" data-aos="zoom-in">
        <ImageGallery
          items={images}
          showPlayButton={false}
          showFullscreenButton={false}
          showNav={true}
          showBullets={true}
          showThumbnails={false} 
          autoPlay={true}
          slideDuration={500}
        />
      </div>
      
                </div>
            </section>
            
        </div>
    )
}

export default Partner
