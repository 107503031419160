import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import pro11 from '../../src/assets/01.1.jpg'
import pro12 from '../../src/assets/01.2.jpg'
import pro13 from '../../src/assets/01.3.jpg'
import pro14 from '../../src/assets/01.4.jpg'
import pro15 from '../../src/assets/01.5.jpg'


import pro21 from '../../src/assets/02.1.jpg'
import pro22 from '../../src/assets/02.2.jpg'
import pro23 from '../../src/assets/02.3.jpg'
import pro24 from '../../src/assets/02.4.jpg'
import pro25 from '../../src/assets/02.5.jpg'


import pro31 from '../../src/assets/03.1.jpg'
import pro32 from '../../src/assets/03.2.jpg'
import pro33 from '../../src/assets/03.3.jpg'
import pro34 from '../../src/assets/03.4.jpg'
import pro35 from '../../src/assets/03.5.jpg'


import pro41 from '../../src/assets/04.1.jpg'
import pro42 from '../../src/assets/04.2.jpg'
import pro43 from '../../src/assets/04.3.jpg'
import pro44 from '../../src/assets/04.4.jpg'
import pro45 from '../../src/assets/04.5.jpg'

import pro51 from '../../src/assets/05.1.jpg'
import pro52 from '../../src/assets/05.2.jpg'
import pro53 from '../../src/assets/05.3.jpg'
import pro54 from '../../src/assets/05.4.jpg'
import pro55 from '../../src/assets/05.5.jpg'

import pro61 from '../../src/assets/06.1.jpg'
import pro62 from '../../src/assets/06.2.jpg'
import pro63 from '../../src/assets/06.3.jpg'
import pro64 from '../../src/assets/06.4.jpg'
import pro65 from '../../src/assets/06.5.jpg'

import pro71 from '../../src/assets/07.1.jpg'
import pro72 from '../../src/assets/07.2.jpg'
import pro73 from '../../src/assets/07.3.jpg'
import pro74 from '../../src/assets/07.4.jpg'
import pro75 from '../../src/assets/07.5.jpg'

import pro81 from '../../src/assets/08.1.jpg'
import pro82 from '../../src/assets/08.2.jpg'
import pro83 from '../../src/assets/08.3.jpg'
import pro84 from '../../src/assets/08.4.jpg'
import pro85 from '../../src/assets/08.5.jpg'

import pro91 from '../../src/assets/09.1.jpg'
import pro92 from '../../src/assets/09.2.jpg'
import pro93 from '../../src/assets/09.3.jpg'
import pro94 from '../../src/assets/09.4.jpg'
import pro95 from '../../src/assets/09.5.jpg'

import pro101 from '../../src/assets/10.1.jpg'
import pro102 from '../../src/assets/10.2.jpg'
import pro103 from '../../src/assets/10.3.jpg'
import pro104 from '../../src/assets/10.4.jpg'
import pro105 from '../../src/assets/10.5.jpg'

import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import AOS from 'aos';
import 'aos/dist/aos.css';

function Product() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const [currentImages, setCurrentImages] = useState([]);

  const mobilsatu = [pro11, pro12, pro13, pro14, pro15,];
  const mobildua = [pro21, pro22, pro23, pro24, pro25, ];
  const mobiltiga = [pro31, pro32, pro33, pro34, pro35, ];
  const mobilempat = [pro41, pro42, pro43, pro44, pro45, ];
  const mobillima = [pro51, pro52, pro53, pro54, pro55, ];
  const mobilenam = [pro61, pro62, pro63, pro64, pro65, ];
  const mobiltujuh = [pro71, pro72, pro73, pro74, pro75, ];
  const mobildelapan = [pro81, pro82, pro83, pro84, pro85, ];
  const mobilsembilan = [pro91, pro92, pro93, pro94, pro95, ];
  const mobilsepuluh = [pro101, pro102, pro103, pro104, pro105, ];

  const imagesMap = {
    'satu': mobilsatu,
    'dua': mobildua,
    'tiga': mobiltiga,
    'empat': mobilempat,
    'lima':mobillima,
    'enam':mobilenam,
    'tujuh':mobiltujuh,
    'delapan':mobildelapan,
    'sembilan':mobilsembilan,
    'sepuluh':mobilsepuluh,
  };

  const renderCarousel = (images) => (
    <Carousel showThumbs={false} showIndicators={false} infiniteLoop={true} autoPlay={true} interval={3000}>
      {images.map((src, index) => (
        <div key={index}>
          <img src={src} alt={`Slide ${index + 1}`} className="h-full w-full shadow-xl mx-auto object-contain transition duration-3000 group-hover:scale-105 sm:h-[250px] rounded-xl cursor-pointer" onClick={() => setCurrentImages(images)} />
        </div>
      ))}
    </Carousel>
  );

  return (
    <div>
      <section id="product">
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
          <header className="text-center">
            <h2 className="text-4xl text-gray-900 sm:text-5xl">Mobil Impian <span className="text-red-600">Anda</span></h2>
            
          </header>
          
          <ul data-aos="fade-up" className="mt-8 grid gap-6 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
            <li className='border-[1px] rounded-xl border-slate-900 shadow-xl'>
              <a className="group block overflow-hidden p-1 w-full h-auto">
                {renderCarousel(mobiltiga)}
                <div className="relative bg-white pt-3 text-left p-9">
                  <h2 className="text-lg text-black group-hover:underline group-hover:underline-offset-4 font-semibold">
                    Rush 1.5 TRD Ultimo Matic Silver 2017
                  </h2>
                  <p className="mt-2 pl-2">
                    <span className="tracking-wider text-gray-900 text-left text-sm">
                      <span className="font-semibold">Kilometer:</span><br />
                      90.000
                      <br />
                      <span className="font-semibold">Kondisi:</span><br />
                      Luar Biasa
                      <br />
                      <span className="font-semibold">Harga:</span><br />
                      <span className="font-bold font-sans text-lg hover:text-red-600">Mulai Dari Rp 184.970.000</span>
                    </span>
                    <a 
        href="https://wa.me/6281398635856?text=Halo,%20saya%20tertarik%20dengan%20Rush 1.5 TRD Ultimo Matic Silver 2017" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <button className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-800">
          DETAILS
        </button> </a>
                  </p>
                </div>
              </a>
            </li>
            <li className='border-[1px] rounded-xl border-slate-900 shadow-xl'>
              <a className="group block overflow-hidden p-1">
                {renderCarousel(mobilsatu)}
                <div className="relative bg-white pt-3 text-left p-9">
                  <h2 className="text-lg text-black group-hover:underline group-hover:underline-offset-4 font-semibold">
                   Rush 1.5 TRD Matic Brown 2018
                  </h2>
                  <p className="mt-2 pl-2">
                    <span className="tracking-wider text-gray-900 text-left text-sm">
                      <span className="font-semibold">Kilometer:</span><br />
                      40.000
                      <br />
                      <span className="font-semibold">Kondisi:</span><br />
                      Luar Biasa
                      <br />
                      <span className="font-semibold">Harga:</span><br />
                      <span className="font-bold font-sans text-lg hover:text-red-600">Mulai Dari Rp 209.970.000</span>
                    </span>
                    <a 
        href="https://wa.me/6281398635856?text=Halo,%20saya%20tertarik%20dengan%20Rush 1.5 TRD Matic Brown 2018" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <button className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-800">
          DETAILS
        </button> </a>
                  </p>
                </div>
              </a>
            </li>
            <li className='border-[1px] rounded-xl border-slate-900 shadow-xl'>
              <a className="group block overflow-hidden p-1">
                {renderCarousel(mobildua)}
                <div className="relative bg-white pt-3 text-left p-9">
                  <h2 className="text-lg text-black group-hover:underline group-hover:underline-offset-4 font-semibold">
                    Rush 1.5 GR Matic Black 2021
                  </h2>
                  <p className="mt-2 pl-2">
                    <span className="tracking-wider text-gray-900 text-left text-sm">
                      <span className="font-semibold">Kilometer:</span><br />
                      70.000
                      <br />
                      <span className="font-semibold">Kondisi:</span><br />
                      Istimewa
                      <br />
                      <span className="font-semibold">Harga:</span><br />
                      <span className="font-bold font-sans text-lg hover:text-red-600">Mulai Dari Rp 234.970.000</span>
                    </span>
                    <a 
        href="https://wa.me/6281398635856?text=Halo,%20saya%20tertarik%20dengan%20Rush 1.5 GR Matic Black 2021" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <button className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-800">
          DETAILS
        </button> </a>
                  </p>
                </div>
              </a>
            </li>
            <li className='border-[1px] rounded-xl border-slate-900 shadow-xl'>
              <a className="group block overflow-hidden p-1">
                {renderCarousel(mobilempat)}
                <div className="relative bg-white pt-3 text-left p-9">
                  <h2 className="text-lg text-black group-hover:underline group-hover:underline-offset-4 font-semibold">
                    Innova V Lux Matic Bensin Black 2014
                  </h2>
                  <p className="mt-2 pl-2">
                    <span className="tracking-wider text-gray-900 text-left text-sm">
                      <span className="font-semibold">Kilometer:</span><br />
                      112.000
                      <br />
                      <span className="font-semibold">Kondisi:</span><br />
                      Istimewa 
                      <br />
                      <span className="font-semibold">Harga:</span><br />
                      <span className="font-bold font-sans text-lg hover:text-red-600">Mulai Dari Rp 198.970.000</span>
                    </span>
                    <a 
        href="https://wa.me/6281398635856?text=Halo,%20saya%20tertarik%20dengan%20Innova V Lux Matic Bensin Black 2014" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <button className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-800">
          DETAILS
        </button> </a>
                  </p>
                </div>
              </a>
            </li>
            <li className='border-[1px] rounded-xl border-slate-900 shadow-xl'>
              <a className="group block overflow-hidden p-1">
                {renderCarousel(mobillima)}
                <div className="relative bg-white pt-3 text-left p-9">
                  <h2 className="text-lg text-black group-hover:underline group-hover:underline-offset-4 font-semibold">
                    Innova 2.0 G Matic Silver 2020
                  </h2>
                  <p className="mt-2 pl-2">
                    <span className="tracking-wider text-gray-900 text-left text-sm">
                      <span className="font-semibold">Kilometer:</span><br />
                      61.000
                      <br />
                      <span className="font-semibold">Kondisi:</span><br />
                      Istimewa
                      <br />
                      <span className="font-semibold">Harga:</span><br />
                      <span className="font-bold font-sans text-lg hover:text-red-600">Mulai Dari Rp 298.970.000</span>
                    </span>
                    <a 
        href="https://wa.me/6281398635856?text=Halo,%20saya%20tertarik%20dengan%20Innova 2.0 G Matic Silver 2020" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <button className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-800">
          DETAILS
        </button> </a>
                  </p>
                </div>
              </a>
            </li>
            <li className='border-[1px] rounded-xl border-slate-900 shadow-xl'>
              <a className="group block overflow-hidden p-1">
                {renderCarousel(mobilenam)}
                <div className="relative bg-white pt-3 text-left p-9">
                  <h2 className="text-lg text-black group-hover:underline group-hover:underline-offset-4 font-semibold">
                    Innova 2.0 G Manual Silver 2021
                  </h2>
                  <p className="mt-2 pl-2">
                    <span className="tracking-wider text-gray-900 text-left text-sm">
                      <span className="font-semibold">Kilometer:</span><br />
                      44.000
                      <br />
                      <span className="font-semibold">Kondisi:</span><br />
                      Istimewa
                      <br />
                      <span className="font-semibold">Harga:</span><br />
                      <span className="font-bold font-sans text-lg hover:text-red-600">Mulai Dari Rp 289.970.000</span>
                    </span>
                    <a 
        href="https://wa.me/6281398635856?text=Halo,%20saya%20tertarik%20dengan%20Innova 2.0 G Manual Silver 2021" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <button className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-800">
          DETAILS
        </button> </a>
                  </p>
                </div>
              </a>
            </li>
            <li className='border-[1px] rounded-xl border-slate-900 shadow-xl'>
              <a className="group block overflow-hidden p-1">
                {renderCarousel(mobiltujuh)}
                <div className="relative bg-white pt-3 text-left p-9">
                  <h2 className="text-lg text-black group-hover:underline group-hover:underline-offset-4 font-semibold">
                    Calya 1.5 G Matic Silver 2017
                  </h2>
                  <p className="mt-2 pl-2">
                    <span className="tracking-wider text-gray-900 text-left text-sm">
                      <span className="font-semibold">Kilometer:</span><br />
                      57.000
                      <br />
                      <span className="font-semibold">Kondisi:</span><br />
                      Istimewa
                      <br />
                      <span className="font-semibold">Harga:</span><br />
                      <span className="font-bold font-sans text-lg hover:text-red-600">Mulai Dari Rp 126.970.000</span>
                    </span>
                    <a 
        href="https://wa.me/6281398635856?text=Halo,%20saya%20tertarik%20dengan%20Calya 1.5 G Matic Silver 2017" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <button className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-800">
          DETAILS
        </button> </a>
                  </p>
                </div>
              </a>
            </li>

            <li className='border-[1px] rounded-xl border-slate-900 shadow-xl'>
              <a className="group block overflow-hidden p-1">
                {renderCarousel(mobildelapan)}
                <div className="relative bg-white pt-3 text-left p-9">
                  <h2 className="text-lg text-black group-hover:underline group-hover:underline-offset-4 font-semibold">
                    Mobilio 1.5 S Manual Putih 2018
                  </h2>
                  <p className="mt-2 pl-2">
                    <span className="tracking-wider text-gray-900 text-left text-sm">
                      <span className="font-semibold">Kilometer:</span><br />
                      70.000
                      <br />
                      <span className="font-semibold">Kondisi:</span><br />
                      Istimewa
                      <br />
                      <span className="font-semibold">Harga:</span><br />
                      <span className="font-bold font-sans text-lg hover:text-red-600">Mulai Dari Rp 144.970.000</span>
                    </span>
                    <a 
        href="https://wa.me/6281398635856?text=Halo,%20saya%20tertarik%20dengan%20Mobilio 1.5 S Manual Putih 2018" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <button className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-800">
          DETAILS
        </button> </a>
                  </p>
                </div>
              </a>
            </li>

            <li className='border-[1px] rounded-xl border-slate-900 shadow-xl'>
              <a className="group block overflow-hidden p-1">
                {renderCarousel(mobilsembilan)}
                <div className="relative bg-white pt-3 text-left p-9">
                  <h2 className="text-lg text-black group-hover:underline group-hover:underline-offset-4 font-semibold">
                    Agya 1.2 G Matic Merah Maroon 2023 
                  </h2>
                  <p className="mt-2 pl-2">
                    <span className="tracking-wider text-gray-900 text-left text-sm">
                      <span className="font-semibold">Kilometer:</span><br />
                      9.000
                      <br />
                      <span className="font-semibold">Kondisi:</span><br />
                      Istimewa
                      <br />
                      <span className="font-semibold">Harga:</span><br />
                      <span className="font-bold font-sans text-lg hover:text-red-600">Mulai Dari Rp 169.970.000</span>
                    </span>
                    <a 
        href="https://wa.me/6281398635856?text=Halo,%20saya%20tertarik%20dengan%20Agya 1.2 G Matic Merah Maroon 2023" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <button className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-800">
          DETAILS
        </button> </a>
                  </p>
                </div>
              </a>
            </li>

            <li className='border-[1px] rounded-xl border-slate-900 shadow-xl'>
              <a className="group block overflow-hidden p-1">
                {renderCarousel(mobilsepuluh)}
                <div className="relative bg-white pt-3 text-left p-9">
                  <h2 className="text-lg text-black group-hover:underline group-hover:underline-offset-4 font-semibold">
                    Calya 1.5 G Manual Merah 2021
                  </h2>
                  <p className="mt-2 pl-2">
                    <span className="tracking-wider text-gray-900 text-left text-sm">
                      <span className="font-semibold">Kilometer:</span><br />
                      44.000
                      <br />
                      <span className="font-semibold">Kondisi:</span><br />
                      Istimewa
                      <br />
                      <span className="font-semibold">Harga:</span><br />
                      <span className="font-bold font-sans text-lg hover:text-red-600">Mulai Dari Rp 144.970.000</span>
                    </span>
                    <a 
        href="https://wa.me/6281398635856?text=Halo,%20saya%20tertarik%20dengan%20Calya 1.5 G Manual Merah 2021" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <button className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-800">
          DETAILS
        </button> </a>
                  </p>
                </div>
              </a>
            </li>

          </ul>
        </div>
      </section>

      {currentImages.length > 0 && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50">
          <ImageGallery
            items={currentImages.map((img) => ({ original: img, thumbnail: img }))}
            showFullscreenButton={true}
            showPlayButton={false}
            showThumbnails={true}
            startIndex={0}
            onClick={() => setCurrentImages([])}
          />
        </div>
      )}
    </div>
  );
}

export default Product;
