import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { FaInstagram } from "react-icons/fa";
import { AiFillTikTok, AiFillFacebook,AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

import logoPng from "../../src/assets/logo-ub-nn.png";
import jemuranPng from "../../src/assets/banner.jpg";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = jemuranPng;
    img.onload = () => setIsImageLoaded(true);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const menuItems = [
    { to: "atas", text: "BERANDA", offset: 0 },
    { to: "about", text: "TENTANG KAMI", offset: 0 },
 
    { to: "product", text: "PRODUK KAMI", offset: 40 },
    { to: "footer", text: "KONTAK", offset: 0 },
  ];

  return (
    <div>
      <section 
        className={`relative bg-gray-900 text-white transition-opacity duration-300 ${
          isImageLoaded ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <div 
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${jemuranPng})` }}
        />
        <div className="absolute inset-0 bg-black opacity-10 z-0"></div>
        
        <header className="relative bg-transparent w-full top-0 z-10">
          <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center gap-12">
                <img
                  alt="Logo"
                  src={logoPng}
                  className="h-20 md:h-3/6 md:w-3/6 mt-1 object-cover"
                  loading="lazy"
                />
              </div>

              <div className="md:hidden">
                <button onClick={toggleMenu} className="text-white">
                  {isMenuOpen ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
                </button>
              </div>

              <nav 
                className={`fixed top-0 right-0 h-full w-full bg-black bg-opacity-75 z-20 transition-transform transform 
                  ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} 
                  md:translate-x-0 md:relative md:bg-transparent md:flex md:items-center md:gap-12`}
              >
                <ul className="flex flex-col md:flex-row items-center gap-3 text-sm md:ml-auto mt-20 md:mt-0">
                  <li className="absolute top-4 right-4 md:hidden">
                    <button onClick={closeMenu} className="text-white">
                      <AiOutlineClose size={24} />
                    </button>
                  </li>
                  {menuItems.map((item) => (
                    <li key={item.to}>
                      <Link
                        activeClass="text-red-600"
                        to={item.to}
                        spy={true}
                        smooth={true}
                        offset={item.offset}
                        duration={500}
                        className="sm:text-sm text-lg cursor-pointer font-bold leading-6 text-white md:text-black-600 font-sans transition hover:text-red-600"
                        onClick={closeMenu}
                      >
                        {item.text}
                      </Link>
                    </li>
                  ))}
                  <li className='flex gap-3 mt-4 md:mt-0'>
                    {[
                      { href: "https://www.instagram.com/mobil.secondpalembang", Icon: FaInstagram, label: "Instagram" },
                      { href: "https://www.tiktok.com/@mobil.secondpalembang", Icon: AiFillTikTok, label: "TikTok" },
                      { href: "https://www.facebook.com/share/4wwyX7AhSFY5imwq/?mibextid=qi2Omg", Icon: AiFillFacebook, label: "Email" }
                    ].map(({ href, Icon, label }) => (
                      <a 
                        key={label}
                        href={href} 
                        aria-label={`${label} (opens in a new tab)`} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className='transition hover:text-red-600'
                      >
                        <Icon className="w-5 h-5 text-white md:text-black-600 hover:text-red-600" />
                      </a>
                    ))}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>

        <div className="relative mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
          <div className="mx-auto max-w-5xl text-center">
            <h1 className="sm:text-6xl text-5xl text-white text-opacity-100 w-full">
              KIM AUTO
              <span className="text-red-600"> PALEMBANG </span>
            </h1>
            <hr className="mt-6 border-slate-800 border-[1px] sm:mx-auto w-44" />
            <p className="mx-auto mt-4 text-shadow-glow text-white max-w-2xl sm:text-2xl/relaxed">
            Temukan Mobil Bekas Impian Anda, Kualitas Terjamin!
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Navbar;