import React, { useEffect, useState, useRef, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Angka() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  // Inisialisasi AOS untuk animasi fade-up
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  // Fungsi callback untuk IntersectionObserver
  const handleObserver = useCallback((entries) => {
    const entry = entries[0];
    setIsVisible(entry.isIntersecting);
  }, []);

  // Menggunakan Intersection Observer untuk mendeteksi apakah section terlihat di layar
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, { threshold: 0.1 });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [handleObserver]);

  // Komponen untuk Animated Counter
  const AnimatedCounter = ({ endValue, duration, showPlus }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
      if (isVisible) {
        let start = 0;
        const increment = endValue / (duration / 100);
        const counterInterval = setInterval(() => {
          start += increment;
          if (start >= endValue) {
            clearInterval(counterInterval);
            setCount(endValue);
          } else {
            setCount(Math.ceil(start));
          }
        }, 100);

        return () => clearInterval(counterInterval); // Bersihkan interval saat komponen tak diperlukan lagi
      }
    }, [isVisible, endValue, duration]);

    return (
      <dd className="text-4xl font-semibold text-red-500 md:text-5xl">
        {count}{showPlus && "+"}
      </dd>
    );
  };

  return (
    <section ref={sectionRef} className="bg-white py-20" >
      <div className="mx-auto max-w-screen-xl ">
        <dl className=" grid grid-cols-2 gap-4  sm:grid-cols-2 lg:grid-cols-4">
          <div className="flex flex-col rounded-lg border border-gray-100 px-4  text-center">
            <dt className="order-last text-lg font-medium  text-gray-500">Klien</dt>
            <AnimatedCounter endValue={100} duration={800} showPlus={true} />
          </div>

          <div className="flex flex-col rounded-lg border border-gray-100 px-4  text-center">
            <dt className="order-last text-lg font-medium  text-gray-500">Mobil Terjual</dt>
            <AnimatedCounter endValue={110} duration={800} showPlus={true} />
          </div>

          <div className="flex flex-col rounded-lg border border-gray-100 px-4  text-center">
            <dt className="order-last text-lg font-medium  text-gray-500">Ready Stock</dt>
            <AnimatedCounter endValue={10} duration={800} showPlus={false} />
          </div>

          <div className="flex flex-col rounded-lg border border-gray-100 px-4  text-center">
            <dt className="order-last text-lg  font-medium text-gray-500">Tahun Pengalaman</dt>
            <AnimatedCounter endValue={5} duration={800} showPlus={true} />
          </div>
        </dl>
      </div>
    </section>
  );
}

export default Angka;
