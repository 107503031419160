import logoPng from "../../src/assets/logo-ub-nn.png";
import banner2Png from '../../src/assets/aa.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

function About() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div>
      <section id="about" className="overflow-hidden sm:grid sm:grid-cols-2 bg-black">
        <div data-aos="fade-right" className="p-8 md:p-12 lg:px-8 lg:py-20 border-2 border-dashed border-black w-full sm:w-3/4 h-full mx-auto">
          <div className="mx-auto max-w-xl text-center sm:text-left">
            <h2 className="text-4xl text-center text-white md:text-5xl">
              TENTANG <span className="text-red-600">KAMI</span>
            </h2>
            <img
              alt="Logo"
              src={logoPng}
              className=" h-24 w-auto sm:h-32 sm:w-auto md:h-32 md:w-32 lg:h-72 lg:w-auto object-cover mx-auto sm:mx-auto"
            />
            <p className="text-white text-center font-sans ">
            Kami adalah dealer mobil bekas yang selalu memprioritaskan kepuasan konsumen. Setiap mobil yang kami jual dijamin berkualitas, seakan seperti baru dengan kekurangan minim. Selain jual beli, kami juga melayani tukar tambah kendaraan Anda.
            </p>
          </div>
        </div>

        <img
        data-aos="fade-left"
          alt="Bihun Production"
          src={banner2Png}
          className="h-[350px] mx-auto w-5/6 p-3 object-cover sm:h-[650px]"
        />
      </section>
    </div>
  );
}

export default About;
