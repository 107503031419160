import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Sectionone() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

 

  return (
    <div>
      <section id="atas" className="bg-white mt-10" >
        <div className="container px-6 mx-auto">
          <h1 className="text-4xl font-extralight text-gray-800 capitalize lg:text-5xl text-center">
            MENGAPA MEMILIH <span className="text-red-600">KAMI?</span>
          </h1>

          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-3 xl:grid-cols-3 text-center" data-aos="fade-up">
            <div className="p-8 space-y-3 text-center">
              <span className="inline-block text-red-500  text-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
                </svg>
              </span>
              <h1 className="text-2xl font-semibold text-gray-800 capitalize ">MOBIL BERKUALITAS</h1>
              <p className="text-gray-600  font-sans">
              Mobil yang kami jual sudah melalui pemeriksaan menyeluruh untuk memastikan performa dan kondisi terbaik. Anda bisa langsung menikmati perjalanan tanpa khawatir.
              </p>
            </div>

            <div className="p-8 space-y-3 text-center">
              <span className="inline-block text-red-500  text-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0 0 12 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 0 1-2.031.352 5.988 5.988 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971Zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 0 1-2.031.352 5.989 5.989 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971Z" />
                </svg>
              </span>
              <h1 className="text-2xl font-semibold text-gray-800 capitalize ">HARGA BERSAING</h1>
              <p className="text-gray-600  font-sans">
              Kami menawarkan harga yang jelas dan kompetitif, membantu Anda mendapatkan mobil impian dengan budget yang pas.
              </p>
            </div>

            <div className="p-8 space-y-3 text-center">
              <span className="inline-block text-red-500  text-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
                </svg>
              </span>
              <h1 className="text-2xl font-semibold text-gray-800 capitalize ">LAYANAN CEPAT & EFISIEN</h1>
              <p className="text-gray-600  font-sans">
              Proses pembelian mudah dan cepat, tanpa ribet, agar Anda bisa segera memiliki mobil Anda.
              </p>
            </div>
          </div>
        </div>

      </section>
    </div>
  );
}

export default Sectionone;
